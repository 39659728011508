import React, { useState, useEffect, Fragment } from 'react'
import { Typography, Link, Box } from '@mui/material'
import policy from '../../src/docs/privacy-policy.md'
import HomeHeader from './HomeHeader'
import Footer from './Footer'
import Markdown from './Markdown'


const PrivacyPolicy = () => {
    //const [tosText, setTosText] = useState('')
    const [privacyPolicyText, setPrivacyPolicyText] = useState('')
    // useEffect(() => {
    // 	fetch(TermsOfUse).then(res => res.text()).then(text => setTosText(text))
    // })

    useEffect(() => {
        fetch(policy).then(res => res.text()).then(text => setPrivacyPolicyText(text))
    })

    return (
        <Fragment>
            <HomeHeader />
            <Box sx={{ bgcolor: 'background.paper', p: 6 }} >

                <Typography align='center' variant='h5' color='primary.main'>
                    PRIVACY POLICY
                </Typography>
                <Markdown className="markdown" key={policy.substring(0, 40)}>
                    {privacyPolicyText}
                </Markdown>
            </Box>
            <Footer />
        </Fragment>

    )
}

export default PrivacyPolicy