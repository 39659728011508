import React from 'react'
import {
    Stack,
    Box,
    Typography,
    Container,
    useMediaQuery
} from '@mui/material'


import GroupsIcon from '@mui/icons-material/Groups';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import FavoriteIcon from '@mui/icons-material/Favorite';



const cards = [1, 2, 3];

const InfoCard = (props) => {
    return (<Box
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            {props.children}
        </Box>
        <Typography gutterBottom align='center' variant="h5" component="h2">
            {props.heading}
        </Typography>
        <Typography align='center' variant='body2' >
            {props.description}
        </Typography>
    </Box>)
}

const About = () => {
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <Container id='features' sx={{ py: 8 }} maxWidth="md">
            {/* End hero unit */}
            <Stack direction={matches ? 'row' : 'column'} spacing={10}>
                <InfoCard heading='Zero Pressure' description='Connect with groups and individuals at local venues or events throughout the world in zero pressure environments'>
                    <GroupsIcon sx={{ fontSize: 100, color: "primary.main" }} />
                </InfoCard>
                <InfoCard heading='Make Friends' description={`Make new friends before you even attend the event.  Traditional dating apps have required people to organise their own dates after meeting.  With Venyou we want to help break the ice so we create the date at the venue for you.`}>
                    <FavoriteIcon sx={{ fontSize: 100, color: "primary.main" }} />
                </InfoCard>
                <InfoCard heading='Plan Ahead' description='Travelling to a new city for the weekend, use Venyou to make new connections before arriving at the venue that night.'>
                    <LocalBarIcon sx={{ fontSize: 100, color: "primary.main" }} />
                </InfoCard>

            </Stack>
        </Container>
    )
}

export default About