import React from 'react'
import {
    Stack,
    Link,
    useMediaQuery
} from '@mui/material'



import appleStore from '../images/apple-store.png'
import googlePlay from '../images/google-play.png'




const Download = () => {
    const iOSUrl = 'https://apps.apple.com/app/venyou/id6471592710';
    const googleUrl = 'https://play.google.com/store/apps/details?id=com.venyou.app&pli=1';

    const imgStyle = {
        height: 55,

    };

    return (
        <Stack direction='column' justifyContent='left' spacing={1}>
            <Link href={iOSUrl} target={'_blank'}>
                <img src={appleStore} style={imgStyle} />
            </Link>
            <Link href={googleUrl} target={'_blank'}>
                <img src={googlePlay} style={imgStyle} />
            </Link>
        </Stack>
    )
}

export default Download

