import React from 'react'
import {
    Box,
    Button,
    Typography,
    Stack,
    TextField,
    Link,
    useMediaQuery
} from '@mui/material'
import Loading from './Loading'

import { useHttpClient } from '../hooks/http-hook'
import { useForm } from 'react-hook-form'
import EmailIcon from '@mui/icons-material/Email';


const Subscribe = () => {
    const { clearError, errorMessage, isLoading, sendRequest } = useHttpClient()
    const [subscribe, setSubscribe] = React.useState(false);
    const matches = useMediaQuery('(min-width:600px)');



    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();
    const { ref, ...rest } = register("email", { pattern: /\S+@\S+\.\S+/, required: true });



    const handleSend = async (data) => {
        clearError()
        try {
            await sendRequest(process.env.REACT_APP_BACKEND_URL + '/subscribe', 'POST',
                JSON.stringify({
                    email: data.email,
                })
                ,
                {
                    'content-type': 'application/json'
                }
            )
            setSubscribe(true)
            reset()

        } catch (error) {

        }
    }



    return (
        <>
            <Box id='subscribe' noValidate component='form' onSubmit={handleSubmit(handleSend)} autoComplete='off' sx={{
                padding: 10,
                backgroundColor: 'primary.secondary',
                marginTop: 20
            }}>
                <Stack  >
                    <Typography variant="h5" component='h1' sx={{ fontWeight: 'bold' }} align='center' color='primary.main'>
                        Subscribe
                    </Typography>
                    <Typography variant='body2' align='center' color='text.secondary'>
                        Keep up to date with our app and upcoming features.
                    </Typography>
                    <Box sx={{ marginTop: 5 }} >
                        <Stack direction={matches ? 'row' : 'column'} justifyContent='center' spacing={2}>
                            <TextField placeholder='Email' sx={{ width: matches ? '20%' : '100%' }}
                                inputRef={ref}
                                {...rest}
                                name='email'
                                id="outlined-error"
                                label={''}
                                error={errors.email ? true : false}
                                helperText={errors.email ? "Invalid Email" : ''}
                                required
                            />
                            <Button variant="contained" startIcon={<EmailIcon />} type='submit'>
                                SUBSCRIBE
                            </Button>
                        </Stack>
                        <Stack justifyContent='center' alignItems={'center'} marginTop={2}>
                            <Typography variant='caption' align='center' color='text.secondary' maxWidth={400} >
                                Upon subscribing, I consent to receiving updates from Venyou and accept the terms outlined in the <Link href='/privacy'>privacy policy</Link>.
                            </Typography>
                        </Stack>
                    </Box>
                    {errorMessage && <Typography variant="caption" component='h1' sx={{ fontWeight: 'bold', color: 'error.main' }} align='center' marginTop={2}  >
                        {`Error Code: ${errorMessage?.errCode || 'Unknown'}, ${errorMessage?.errMsg || errorMessage}. Contact Support at admin@venyou.app.`}
                    </Typography>}
                    {subscribe && <Typography variant="h5" component='h1' sx={{ fontWeight: 'bold' }} align='center' color='primary.main' marginTop={2}>
                        Thanks for your subscription
                    </Typography>}
                    {isLoading && <Loading />}
                </Stack>

            </Box>
        </>

    )
}

export default Subscribe