import React, { useState, useEffect, Fragment } from 'react'
import { Typography, Link, Box, Container, useMediaQuery } from '@mui/material'

import HomeHeader from './HomeHeader'



const NotFound = () => {
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <Fragment>
            <HomeHeader />
            <Container maxWidth="sm">
                <Box sx={{
                    bgcolor: 'background.paper', pt: 8,
                    pb: 6,
                }}  >
                    <Typography
                        component="h1"
                        variant={matches ? 'h2' : 'h4'}
                        align="center"
                        color="primary.main"
                        gutterBottom
                    >
                        Error 404!
                    </Typography>
                    <Typography variant={matches ? 'h5' : 'subtitle1'} align="center" color="text.secondary" paragraph>
                        The page your are trying to visit doesn't exist.
                    </Typography>
                </Box>
            </Container>

        </Fragment>

    )
}

export default NotFound