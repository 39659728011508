import React from 'react'
import {
    Paper
} from '@mui/material'

import useMediaQuery from '@mui/material/useMediaQuery';



const VenyouMovieContainer = () => {
    const matches = useMediaQuery('(min-width:600px)');

    let videoHeight = 650
    if (!matches) {
        videoHeight = 500
    }
    return (
        <Paper square sx={{
            background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
            height: videoHeight,
            width: '90%',
            margin: 'auto',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        }}>
            <iframe width="100%" height="100%" title='venyou-ad'
                src='https://www.youtube.com/embed/P0nZMiYmLyU?controls=1'>
            </iframe>
        </Paper>

    )
}

export default VenyouMovieContainer