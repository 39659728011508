import React from 'react'
import {
    Box,
    Typography,
    Stack,
    Link
} from '@mui/material'

import '../style.css'
import Download from './Download'
import { useParams } from 'react-router-dom'


const InvitationHandler = () => {
    const { id } = useParams()

    React.useEffect(() => {
        try {
            window.location.replace(`venyou://InviteEventInfo?eventId=${id}`);
            // window.location.replace(`exp://InviteEventInfo?eventId=${id}`);
        } catch (error) {
            console.warn(error);
            const userAgent = navigator.userAgent || window.opera;
            if (/windows phone/i.test(userAgent)) {
                window.location.replace('https://venyou.app/');
            }
            if (/android/i.test(userAgent)) {
                window.location.replace(
                    'https://play.google.com/store/apps/details?id=com.venyou.app&pli=1'
                );
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                window.location.replace('https://apps.apple.com/app/venyou/id6471592710');
            }
            window.location.replace('https://venyou.app/');
        }
    }, [id])
    return (
        <>
            <Box paddingTop={5}>
                <Typography variant="h1" sx={{ fontWeight: 'bold' }} align='center' color='primary.main'>
                    VENYOU
                </Typography>
            </Box>
            <Stack >
                <Typography variant="h5" component='h1' sx={{ fontWeight: 'bold' }} align='center' color='primary.main'>
                    You have an invitation!
                </Typography>
                <Typography variant='body2' align='center' color='text.secondary' marginTop={3}>
                    Hi I've invited you to my event. Download Venyou from the links below
                    and browse other people going and make new connections prior to arriving.
                    Want to know more about venYOU before accepting visit our site <Link target="_blank" rel='noreferrer' href='/home'>here</Link>
                </Typography>
                <Typography color={'red'} align='center' style={{ fontWeight: 'bold' }}>After successfully logging into the app, return to this page and simply refresh it to seamlessly navigate to the Event Invitation.</Typography>
                <Box sx={{ marginTop: 5 }}>
                    <Stack justifyContent='center' alignItems={'center'} marginTop={5} spacing={2}>
                        <Download />
                    </Stack>
                </Box>
            </Stack>
        </>

    )
}

export default InvitationHandler