import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const preferedMethods = [
    'Phone Call',
    'SMS',
    'Email'
];

export default function PreferedContactMethod(props) {

    return (
        <div>
            <FormControl fullWidth sx={{ mt: 2, minWidth: 120 }}>
                <InputLabel shrink htmlFor="select-multiple-native">
                    How would you like us to contact you.
                </InputLabel>
                <Select
                    multiple
                    native
                    value={props.preferedMethod}
                    // @ts-ignore Typings are not considering `native`
                    onChange={props.setPreferedMethod}
                    label="Choose how you'd like us to contact you."
                    inputProps={{
                        id: 'select-multiple-native',
                    }}
                >
                    {preferedMethods.map((preferedMethod) => (
                        <option key={preferedMethod} value={preferedMethod}>
                            {preferedMethod}
                        </option>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
