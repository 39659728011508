import * as React from 'react';
import {

    Typography,
    Box,
    Card,
    CardMedia,
    Button,
    Stack,
    Container,
    Modal,
    useMediaQuery
} from '@mui/material'




import venyoubgImage from '../images/VENYOU-At-Kingpin-everglow.jpg'
import venyouMobileScreen from '../images/venyouMobileScreen.png'
import venyouLaunchImage from '../images/checking-venyou.jpg'
import Download from './Download';
import CountDownLayout from '../layout/CountDown';
import Countdown from 'react-countdown';



const Hero = (props) => {
    const [open, setOpen] = React.useState(false);
    const matches = useMediaQuery('(min-width:600px)');
    const handleOpen = () => {
        setOpen(true)

    };


    const handleClose = () => setOpen(false);
    // const [timeRemaining, setTimeRemaining] = React.useState(launchDate)
    const launchDate = new Date('February 19, 2023 03:24:00');
    const launched = props.hasLaunched

    const style = {
        display: 'flex',
        flexDirection: 'column',
        justifyContents: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        // bgcolor: 'background.paper',
        bgcolor: 'ghostwhite.main',
        p: 4,
        background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url(${venyouLaunchImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'contain',
        backgroundSize: 'cover'
    };



    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return (
                <>
                    <Typography variant='h3' component="h1" color='customWhite.main' sx={{ marginBottom: 5, marginTop: 20 }} >
                        Venyou has finally arrived
                    </Typography>
                    <Download />;
                </>)
        } else {
            // Render a countdown
            return (
                <CountDownLayout days={days} hours={hours} minutes={minutes} seconds={seconds} />
            )

        }
    };

    let backgroundHeight = 900
    let headingMarginTop = 12.5
    let headingMarginLeft = '30%'
    let countdownButtonMarginTop = 20

    if (!matches) {
        backgroundHeight = 400
        headingMarginTop = 5
        headingMarginLeft = '5%'
        countdownButtonMarginTop = 10
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box component={'dialog'} sx={style}>
                    <Countdown date={launchDate} renderer={renderer} />
                    <Button variant="contained" onClick={handleClose} sx={{ marginTop: countdownButtonMarginTop }}>
                        close
                    </Button>
                </Box>
            </Modal>
            <Card square sx={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),url(${venyoubgImage})`,
                height: backgroundHeight,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: headingMarginLeft, marginTop: headingMarginTop, alignItems: 'center', marginRight: '15%' }}>

                    <Stack direction={'row'} alignItems='center' spacing={5}>
                        <CardMedia
                            component="img"
                            sx={{ width: 350, display: { xs: 'none', sm: 'block' } }}
                            image={venyouMobileScreen}
                            alt='Venyou Mobile screen'
                        />
                        <Container disableGutters maxWidth="sm" component="main">
                            <Typography variant={matches ? 'h3' : 'h5'} color='#ffff'>
                                Connect at an Event or Venue
                            </Typography>
                            <Typography variant={matches ? 'h5' : 'body2'} component='p' align='left' color="#ffff" marginBottom={matches ? 2 : 7} >
                                Australia's newest socialising App, connecting groups and individuals at events or venues in zero pressure environments
                            </Typography>
                            {!launched ? <Stack direction="row" spacing={2} sx={{ marginTop: 1, marginBottom: 25 }}>
                                <Button variant="contained" sx={{ borderRadius: 20, backgroundColor: 'green' }} onClick={handleOpen}>
                                    LAUNCH COUNTDOWN
                                </Button>
                            </Stack>
                                : <Download />}
                        </Container>
                    </Stack>

                </Box>
            </Card>
        </>

    );
}


export default Hero;