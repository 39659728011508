import PickAVenyou from './pick_a_venue.json';
import SetYourLocation from './set_your_location.json';
import CreateYourEvent from './create_your_event.json';
import JoinTheNight from './join_the_night.json';
import InviteFriends from './invite_friends.json';
import MeetNewFriends from './meet_new_friends.json';


const LottieAnimations = {
    OnBoardingAnimations: [
        PickAVenyou,
        JoinTheNight,
        MeetNewFriends,
        CreateYourEvent,
        InviteFriends,
        SetYourLocation,
    ]
}

export default LottieAnimations;