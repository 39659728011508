import React from 'react'
import HomeHeader from '../HomeHeader'
import Footer from '../Footer'

const MeetFriends = props => {
    return (
        <React.Fragment>
            <HomeHeader title='back' link='/support' />
            <div>Meet Friends</div>
            <Footer />
        </React.Fragment>
    )
}


export default MeetFriends