import React from 'react'
import {
    Typography,
    Box,
    Card,
    CardMedia,
    Stack,
    Container,
    useMediaQuery
} from '@mui/material'

import venyouMobileScreen from '../images/venyouMobileScreen.png'
import venyouMobileVenueJoin from '../images/venyouMobileVenueJoin.png'

const Plan = () => {
    const matches = useMediaQuery('(min-width:600px)');

    let direction = 'row'
    let cardWidth = 300
    if (!matches) {
        direction = 'column'
        cardWidth = 180
    }

    //add this back to cardmedia sx value
    //, display: { xs: 'none', sm: 'block' } 
    return (

        <Card square sx={{
            height: 900,
            paddingInline: '10%'
        }}>

            <Box alignItems='center' sx={{
                display: 'flex', flexDirection: direction, marginTop: 12.5
            }}>
                <Stack direction={'row'}>
                    <CardMedia
                        component="img"
                        sx={{ width: cardWidth }}
                        image={venyouMobileScreen}
                        alt='Venyou Mobile screen'
                    />
                    <CardMedia
                        component="img"
                        sx={{ width: cardWidth }}
                        image={venyouMobileVenueJoin}
                        alt='Venyou Mobile screen'
                    />

                </Stack>

                <Container maxWidth="sm" component="main" >
                    <Stack spacing={matches ? 4 : 1}>
                        <Typography variant="h5" component='h1' sx={{ fontWeight: 'bold', marginTop: 3 }} color='primary.main'>
                            Plan your night
                        </Typography>
                        <Typography variant='subtitle1' color='primary.main'>
                            Search venues in your local area.
                        </Typography>
                        <Typography variant='body2' align='left' color='text.secondary' >
                            Just select the venue you want to attend at a particular night.  Either search for venues near your location or select your favourite.
                        </Typography>
                        <Typography variant='subtitle1' color='primary.main'>
                            Join the event to meet others.
                        </Typography>
                        <Typography variant='body2' align='left' color='text.secondary'>
                            Simply join the event for that night and start meeting others attending the same event.  Want to see who's attending but stay hidden,
                            then go incognito.
                        </Typography>
                    </Stack>
                </Container>
            </Box>
        </Card>
    );
}

export default Plan