import React from 'react'
import Investors from './Investors'
import Partners from './Partners'
import { Typography, Stack, Box, useMediaQuery, Paper } from '@mui/material'

const Community = () => {

    const matches = useMediaQuery('(min-width:600px)');



    return (
        <>
            <Typography fontWeight={'bold'} color='primary.main' textAlign={'center'} marginTop={20} variant={matches ? 'h4' : 'h5'}>Join the Venyou Community</Typography>

            <Box margin={5} sx={{ flexGrow: 1 }} id='join-venyou' >
                <Stack direction={matches ? 'row' : 'column'} spacing={5} justifyContent='center'>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                            maxWidth: 600
                        }}
                    >
                        <Typography color='primary.main' textAlign={'left'} marginTop={5} variant={matches ? 'h6' : 'subtitle1'}>Would  you like to become an Investor?</Typography>
                        <Investors />
                    </Paper>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                            maxWidth: 600
                        }}
                    >
                        <Typography color='primary.main' textAlign={'left'} marginTop={5} variant={matches ? 'h6' : 'subtitle1'}>Would you like to become a Partner?</Typography>
                        <Partners />
                    </Paper>

                </Stack>

            </Box>
        </>

    )
}

export default Community