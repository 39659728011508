import * as React from 'react';

import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';

import Typography from '@mui/material/Typography';

import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import HomeHeader from './HomeHeader';
import { useMediaQuery } from '@mui/material';


const tiers = [
    {
        title: 'Free',
        price: '0',
        description: [
            'Full functionality',
            'Limited time'
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
    },
    {
        title: 'Regular',
        // subheader: 'Most popular',
        price: '9.95',
        description: [
            'Save favourite venues',
            'Go Incognito'
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
    },
    {
        title: 'Premium',
        price: '19.95',
        description: [
            'Save favourite venues',
            'Send photos in chats',
            'Select your prefered location',
            'Save and edit invite Lists - Coming Soon',
            'Use previous events to create new ones - Coming Soon'

        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
    },
];


function PricingContent() {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <React.Fragment>
            <HomeHeader />
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />

            {/* Hero unit */}
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant={matches ? 'h2' : 'h4'}
                    align="center"
                    color="primary.main"
                    gutterBottom
                >
                    Pricing
                </Typography>
                <Typography variant={matches ? 'h5' : 'body1'} align="center" color="text.secondary" component="p">
                    Thank you for choosing our Venyou! We are pleased to offer it to you for free initially.
                    However when the time comes, to continue using all of its features, a subscription will be required.
                    We appreciate your understanding and look forward to your continued use of our app.
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {tiers.map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid
                            item
                            key={tier.title}
                            xs={12}
                            sm={tier.title === 'Premium' ? 12 : 6}
                            md={4}
                        >
                            <Card>
                                <CardHeader
                                    title={tier.title}
                                    subheader={tier.subheader}
                                    titleTypographyProps={{ align: 'center' }}
                                    action={tier.title === 'Regular' ? <StarIcon /> : null}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="primary.main">
                                            ${tier.price}
                                        </Typography>
                                        <Typography variant="h6" color="primary.main">
                                            /mo
                                        </Typography>
                                    </Box>
                                    <ul>
                                        {tier.description.map((line) => (
                                            <Typography
                                                component="li"
                                                variant={matches ? 'subtitle1' : 'caption'}
                                                align="center"
                                                key={line}
                                            >
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    {/* <Button fullWidth variant={tier.buttonVariant}>
                                        {tier.buttonText}
                                    </Button> */}
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default function Pricing() {
    return <PricingContent />;
}