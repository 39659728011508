import React from 'react'
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Link,
    useMediaQuery
} from '@mui/material'


const HomeHeader = ({ title = 'Home', link = '/home' }) => {

    const matches = useMediaQuery('(min-width:600px)');
    console.log(link)

    const displayFullHeader = () => {
        return (
            < >
                <Typography variant="h3" color="primary.main" noWrap sx={{ flexGrow: 1 }}>
                    Venyou
                </Typography>
                <nav>
                    <Link
                        variant="button"
                        color="primary.main"
                        href={link}
                        sx={{ my: 1, mx: 1.5 }}
                    >
                        {title}
                    </Link>

                </nav>
            </>
        )
    }

    const displayHomeOnly = () => {
        return (
            < >
                <nav>
                    <Link
                        variant="button"
                        color="primary.main"
                        href={link}
                        sx={{ my: 1, mx: 1.5 }}
                    >
                        {title}
                    </Link>

                </nav>
            </>
        )
    }
    return (
        <Box sx={{ flexGrow: 1 }} >
            <AppBar
                position="relative"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}

            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    {!matches ? displayHomeOnly() : displayFullHeader()}

                </Toolbar>
            </AppBar>
        </Box>

    )
}

export default HomeHeader