import HomeHeader from '../HomeHeader'
import LottieAnimations from '../../animations/animations'
import Lottie from 'lottie-react'
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Footer from '../Footer';
import ContactUs from '../ContactUs';
import { Button, CardActions, CardMedia } from '@mui/material';
import { Link } from '@mui/material';

const content = {

    cards: [0, 1, 2, 3, 4, 5],
    links: ['pick-a-venue', 'join-event', 'meet', 'create', 'invite', 'set-location'],
    heading: ['Pick A Venue', 'Join The Night', 'Meet New Friends', 'Create Your Event', 'Invite Friends', 'Set Your Locaiton'],
    caption: [`Exciting venues await! Tap to pick your next adventure.  Simply scroll through the list of options and click on the one you want to attend`,
        `Ready to meet new people? Click on "I'm interested" to attend this venue and connect with like-minded individuals!`,
        `Ready to mingle before the event? Scroll through the attendee list and find your perfect match! Start chatting and make meaningful connections that could last beyond the event. Don't miss out on this opportunity to network and socialize with like-minded individuals.`,
        `Create your own event and make unforgettable memories with friends! Tap on + at the right top part of the home screen to start planning at this venue.  If you make your event public, people you invite can invite others.`,
        `Get the party started and invite your friends to your created event! When the event is created, go to invite attendees.  Select your friends and tap to share the venue details and start counting down the days.`,
        `Change your scenery and explore new horizons! Navigate to your profile settings and tap to select a different location in another state or even country and discover exciting venues nearby.`]
}



function Support() {

    return (
        <React.Fragment>
            <CssBaseline />
            <HomeHeader />
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="primary.main"
                            gutterBottom
                        >
                            Support
                        </Typography>
                        <Typography
                            component='h4'
                            variant="h4"
                            align="center"
                            color="text.secondary"
                            gutterBottom
                        >
                            How to use the app
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            Welcome to our application support page! Here, you'll find all the information you need to make the most of our app.
                            If you require any further assistance, please don't hesitate to get in touch with us using the contact page below.
                        </Typography>
                    </Container>
                </Box>
                <Container sx={{ py: 8 }} >
                    {/* End hero unit */}
                    <Grid container spacing={6}>
                        {content.cards.map((card) => (
                            <Grid item key={card} xs={12} sm={6} md={4}>
                                <Card
                                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                                >
                                    <CardMedia sx={{ padding: 2 }}>
                                        <Lottie animationData={LottieAnimations.OnBoardingAnimations[card]} />
                                    </CardMedia>
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant='h6' color='primary.main' >
                                            {content.heading[card]}
                                        </Typography>
                                        <Typography variant='body1' color='text.secondary'>
                                            {content.caption[card]}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Link color='primary.main' href={`/${content.links[card]}`}>learn more</Link>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
            <ContactUs />
            {/* Footer */}
            <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                <Footer />
            </Box>
            {/* End footer */}
        </React.Fragment>
    )
}

export default Support