import React from 'react'
import {
    Typography,
    Box,
    Card,
    CardMedia,
    Stack,
    Container,
    useMediaQuery
} from '@mui/material'

import venyouMobileAttendees from '../images/venyouMobileAttendees.png'
import venyouMobileMatches from '../images/venyouMobileMatches.png'

const Pick = () => {
    const matches = useMediaQuery('(min-width:600px)');

    let direction = 'row'
    let cardWidth = 300
    let cardMarginTop = 12.5
    let cardHeight = 900
    if (!matches) {
        direction = 'column'
        cardWidth = 180
        cardMarginTop = 5
        cardHeight = 700
    }
    return (

        <Card square sx={{
            height: cardHeight,
            paddingInline: '10%',
            marginTop: 2
        }}>

            <Box alignItems='center' sx={{
                display: 'flex', flexDirection: direction, marginTop: cardMarginTop,
            }}>
                <Container maxWidth="sm" component="main" >
                    <Stack spacing={matches ? 4 : 1}>
                        <Typography variant="h5" component='h1' sx={{ fontWeight: 'bold' }} color='primary.main'>
                            Meet new friends
                        </Typography>
                        <Typography variant='subtitle1' color='primary.main'>
                            Browse others just like you attending that night
                        </Typography>
                        <Typography variant='body2' align='left' color='text.secondary'>
                            Browse and match with others attending the event.  After you match, chat and get to know new people prior to arriving
                            at the event.
                        </Typography>
                        {/* <Typography variant='subtitle1' color='primary.main'>
                            Connect at an Event or Venue
                        </Typography>
                        <Typography variant='body2' align='left' color='text.secondary' >
                            Australia's newest socialising App, connecting groups and individuals at events or venues in zero pressure environments
                        </Typography> */}
                    </Stack>
                </Container>
                <Stack direction={'row'} marginTop={5} >
                    <CardMedia
                        component="img"
                        sx={{ width: cardWidth }}
                        image={venyouMobileAttendees}
                        alt='Venyou Mobile screen'
                    />
                    <CardMedia
                        component="img"
                        sx={{ width: cardWidth }}
                        image={venyouMobileMatches}
                        alt='Venyou Mobile screen'
                    />
                </Stack>
            </Box>
        </Card>
    );
}

export default Pick