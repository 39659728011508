import React from 'react'
import { Typography, useMediaQuery, Link } from '@mui/material'

const Partners = () => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <Typography variant={!matches ? 'caption' : 'body1'} color='text.secondary'>
            Do you have a venue that you want to bring to the app and attract people?
            We want to onboard as many venues as we can and help bring the people to you, so get in touch to discuss partnership opportunities.
            {' '}<Link href='#contact-us'>Contact Us.</Link>
        </Typography>
    )
}

export default Partners