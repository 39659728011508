import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import PreferedContactMethod from './PreferedContactMethod';
import Loading from './Loading'
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';


// import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import '../style.css'


import { useHttpClient } from '../hooks/http-hook';
import { useForm } from 'react-hook-form'


const ContactUs = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const [open, setOpen] = React.useState(false);
    const [preferedMethod, setPreferedMethod] = React.useState([]);
    const [requestSent, setRequestSent] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState()
    const { clearError, errorMessage, isLoading, sendRequest } = useHttpClient()
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm();

    const handlePreferedMethod = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setPreferedMethod(value);
    };

    const onSubmit = async (data) => {
        try {
            clearError()
            await sendRequest(process.env.REACT_APP_BACKEND_URL + '/contact-us', 'POST',
                JSON.stringify({
                    name: data.name,
                    surname: data.surname,
                    phone: phoneNumber,
                    email: data.email,
                    preferedContactMethod: preferedMethod[0],
                    request: data.request,
                })
                ,
                {
                    'content-type': 'application/json'
                }
            )
            setRequestSent(true)

        } catch (error) {
            return
        }
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        clearError()
        reset()
        setRequestSent(false)
        setPreferedMethod([])
        setPhoneNumber('')
    };

    const handlePhoneInput = (value) => {
        setPhoneNumber(value)
    }

    let dialogActions


    if (!requestSent) {
        dialogActions = <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type='submit' >Send</Button>
        </DialogActions>
    } else {
        dialogActions = <DialogActions >
            <Button onClick={handleClose}>Close</Button>
        </DialogActions>
    }

    let buttonBoarderRadius = 20

    if (!matches) {
        buttonBoarderRadius = 2
    }


    return (
        <>
            <Dialog open={open} onClose={handleClose} >
                <Box component={'form'} onSubmit={handleSubmit(onSubmit)} >
                    <DialogTitle>Contact Us</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Let us know how we can help.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            name='name'
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            required
                            {...register("name")}
                        />
                        <TextField
                            margin="dense"
                            name='surname'
                            label="Last Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            required
                            {...register("surname")}
                        />
                        <TextField

                            margin="dense"
                            id='email'
                            name='email'
                            label="Email Address"
                            fullWidth
                            variant="standard"
                            required
                            {...register("email", { pattern: /\S+@\S+\.\S+/ })}
                            error={errors.email ? true : false}
                            helperText={errors.email ? "Invalid Email" : ''}

                        />
                        <Box sx={{ mt: 3 }} >
                            <PhoneInput
                                defaultCountry='AU'
                                placeholder="Enter phone number"
                                limitMaxLength={true}
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                                error={phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                            />
                        </Box>
                        <TextField
                            sx={{ marginTop: 3 }}
                            name='request'
                            label="Comments"
                            multiline
                            fullWidth
                            variant="filled"
                            required
                            {...register("request")}
                        />
                        <PreferedContactMethod setPreferedMethod={handlePreferedMethod} preferedMethod={preferedMethod} />
                    </DialogContent>
                    {isLoading && <Loading />}
                    {errorMessage && <Typography variant="caption" component='h1' sx={{ fontWeight: 'bold', color: 'error.main' }} align='center' marginTop={2}  >
                        {`Error Code: ${errorMessage?.errCode || 'Unknown'}, ${errorMessage?.errMsg || errorMessage}. Contact Support at admin@venyou.app.`}
                    </Typography>}
                    {requestSent && <Typography variant="caption" component='h1' sx={{ fontWeight: 'bold' }} align='center' marginTop={2}  >
                        Thank you, your message has been sent to our support team.
                    </Typography>}
                    {dialogActions}
                </Box>
            </Dialog>
            <Paper id='contact-us' square={true} sx={{
                padding: 5,
                backgroundColor: 'primary.main',
                marginTop: 10

            }}>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={10} >
                    <Typography height={100} width={250} variant={matches ? 'h5' : 'caption'} component='p' align='left' color='#ffff' padding={2}>
                        Need support or have a suggestion?
                    </Typography>
                    {matches && <Typography variant='subtitle1' height={100} width={400} component='p' textAlign={'left'} paddingTop={2} color="#ffff"  >
                        Contact us for issues you're having with the app or if you have any questions about Venyou.
                    </Typography>}
                    <Box display={'flex'} alignItems={'center'} >
                        <Stack spacing={2} direction='row'>
                            <Button variant="outlined" color='customWhite' sx={{ borderRadius: buttonBoarderRadius, }} onClick={handleOpen} >
                                CONTACT US
                            </Button>
                        </Stack>
                    </Box>

                </Stack>
            </Paper>
        </>

    )
}

export default ContactUs