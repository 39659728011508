import * as React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

function MarkdownListItem(props) {
    return <Box component="li" sx={{ mt: 1, typography: 'subtitle1' }} {...props} />;
}

const options = {
    overrides: {
        h1: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h4',
                component: 'h1',
                color: 'primary.main'
            },
        },
        h2: {
            component: Typography,
            props: { gutterBottom: true, variant: 'h6', component: 'h2', color: 'primary.main' },
        },
        h3: {
            component: Typography,
            props: { gutterBottom: true, variant: 'subtitle1', color: 'primary.main' },
        },
        h4: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'caption',
                paragraph: true,
                color: 'primary.main'
            },
        },
        p: {
            component: Typography,
            props: { paragraph: true, color: 'text.secondary' },
        },
        a: { component: Link },
        li: {
            component: MarkdownListItem,
            props: { color: 'text.secondary' },


        }

    },
};

export default function Markdown(props) {
    return <ReactMarkdown options={options} {...props} />;
}