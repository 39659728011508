import React from 'react'
import {
    Typography,
    Box,
    Card,
    CardMedia,
    Stack,
    Container,
    useMediaQuery
} from '@mui/material'

import venyouMobileLocation from '../images/venyouMobileLocation.png'
import venyouMobileSydney from '../images/venyouMobileSydney.png'

const Location = () => {
    const matches = useMediaQuery('(min-width:600px)');

    let direction = 'row'
    let cardWidth = 300
    let cardMarginTop = 12.5
    if (!matches) {
        direction = 'column'
        cardWidth = 180
        cardMarginTop = 5
    }
    return (

        <Card square sx={{
            height: 900,
            paddingInline: '10%',
            marginTop: 2
        }}>

            <Box alignItems='center' sx={{
                display: 'flex', flexDirection: direction, marginTop: cardMarginTop,
            }}>
                <Container maxWidth="sm" component="main" >
                    <Stack spacing={matches ? 4 : 1}>
                        <Typography variant="h5" component='h1' sx={{ fontWeight: 'bold' }} color='primary.main'>
                            Change your location
                        </Typography>
                        <Typography variant='subtitle1' color='primary.main'>
                            Change your location to a prefered one
                        </Typography>
                        <Typography variant='body2' align='left' color='text.secondary' >
                            Planning to go away for the weekend or travel to another country.  Change your location in settings to your intended
                            destination.
                        </Typography>
                        <Typography variant='subtitle1' color='primary.main'>
                            Browse venues at the new location.
                        </Typography>
                        <Typography variant='body2' align='left' color='text.secondary' >
                            Once your new location is set venues will appear in the surrounding area.  Browse venues and join events before you even travel and start meeting
                            new people.  Imagine planning a holiday and before you get there you've already met new people and made new friends.
                        </Typography>
                    </Stack>
                </Container>
                <Stack direction='row' marginTop={5}>
                    <CardMedia
                        component="img"
                        sx={{ width: cardWidth }}
                        image={venyouMobileLocation}
                        alt='Venyou Mobile screen'
                    />
                    <CardMedia
                        component="img"
                        sx={{ width: cardWidth }}
                        image={venyouMobileSydney}
                        alt='Venyou Mobile screen'
                    />
                </Stack>
            </Box>
        </Card>
    );
}

export default Location