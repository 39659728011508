import React, { useState, useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import HomeHeader from './HomeHeader';
import terms from '../../src/docs/terms-and-conditions.md'
import Markdown from './Markdown';
import Footer from './Footer';


const Terms = () => {

    const [termsText, setTermsText] = useState('')


    useEffect(() => {
        fetch(terms).then(res => res.text()).then(text => setTermsText(text))
    })

    return (
        <>
            <HomeHeader />
            <Box sx={{ bgcolor: 'background.paper', p: 6 }} >
                <Typography align='center' variant='h5' color='primary.main'>
                    TERMS AND CONDITIONS OF USE
                </Typography>
                <Markdown className="markdown" key={terms.substring(0, 40)}>
                    {termsText}
                </Markdown>
            </Box>
            <Footer />
        </>

    )
}

export default Terms