import React from 'react'
import {
  Stack,
  Box,
  Typography,
  Link,
  IconButton,
} from '@mui/material'
import { Instagram, Twitter, YouTube } from '@mui/icons-material';
import { styled } from '@mui/material/styles'





const Copyright = () => {
  return (
    <Typography variant="caption" color="text.secondary" align="center" >
      {'Copyright © '}
      <Link color="inherit" href="#">
        Venyou
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const InstaIconButton = styled(IconButton)`
  background: linear-gradient(rgba(254,218,117),rgba(250,126,30),rgba(214,41,118),rgba(150,47,191),rgba(79,91,213));

  :hover {
    color: #962fbf;
    background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  }
`;

const TwitterIconButton = styled(IconButton)`
  
  :hover {
    color: #03a9f4;
  }
`;


const YoutubeIconButton = styled(IconButton)`
 
  :hover {
    color: red;
  }
`;

export const Footer = (props) => {
  return (
    <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
      <Stack justifyContent="center" alignItems="center" direction="row" spacing={1} sx={{ marginBottom: 5, }}>
        <Link href='https://www.instagram.com/venyouco/' target={'_blank'} color={'#ffffff'} component={InstaIconButton} sx={{ boxShadow: 5 }}>
          <Instagram />
        </Link>

        <Link href='https://twitter.com/venyouverse' color={'#ffffff'} target={'_blank'} component={TwitterIconButton} sx={{ backgroundColor: '#1D9BF0', boxShadow: 5 }}>
          <Twitter />
        </Link>
        <Link href='https://www.youtube.com/channel/UC0i8wqtsT54q3vmubd59dHQ' color={'#ffffff'} target={'_blank'} component={YoutubeIconButton} sx={{ backgroundColor: 'red', boxShadow: 5 }}>
          <YouTube />
        </Link>
      </Stack>
      <Stack spacing={1}>
        <Copyright />
        <Typography variant="caption" color="text.secondary" align="center" >
          {'By signing up, you agree to our '}
          <Link color="inherit" href='/terms' >
            Terms
          </Link>
          {'.'}
        </Typography>
        <Typography variant="caption" color="text.secondary" align="center" >
          {'See how we use your data in our '}
          <Link color="inherit" href='/privacy' >
            Privacy Policy
          </Link>
          {'.'}
        </Typography>
        <Typography variant="caption" color="text.secondary" align="center" >
          {'At Venyou we want everyone to be safe, check out our '}
          <Link color="inherit" href='/community-guidelines' >
            Community Guidelines
          </Link>
          {'.'}
        </Typography>

      </Stack>

    </Box>
  )
}

export default Footer