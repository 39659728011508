import React from 'react'
import { Typography, Stack, Box } from '@mui/material'


const CountDownLayout = (props) => {
    let daysWidth = 100

    if (props.days >= 100) {
        daysWidth = 130
    }
    return (
        <>
            <Typography variant='h3' component="h1" color='customWhite.main' sx={{ marginBottom: 5, marginTop: 3 }} >
                Launch Countdown
            </Typography>
            <Box borderRadius={5} sx={{ backgroundColor: 'primary.main', width: { daysWidth }, height: 100, padding: 2, marginBottom: 10 }}>
                <Typography variant='h2' textAlign='center' color='customWhite.main' >
                    {props.days}
                </Typography>
                <Typography variant='subtitle1' textAlign={'center'} color='customWhite.main' sx={{ marginTop: 2 }}>
                    Days
                </Typography>
            </Box>
            <Stack direction={'row'} spacing={4}>
                <Box borderRadius={5} sx={{ backgroundColor: 'primary.main', width: 100, height: 100, padding: 2 }}>
                    <Typography variant='h2' textAlign={'center'} color='customWhite.main' >
                        {props.hours}
                    </Typography>
                    <Typography variant='subtitle1' textAlign={'center'} color='customWhite.main' sx={{ marginTop: 2 }}>
                        Hours
                    </Typography>
                </Box>
                <Box borderRadius={5} sx={{ backgroundColor: 'primary.main', width: 100, height: 100, padding: 2 }}>
                    <Typography variant='h2' textAlign={'center'} color='customWhite.main' >
                        {props.minutes}
                    </Typography>
                    <Typography variant='subtitle1' textAlign={'center'} color='customWhite.main' sx={{ marginTop: 2 }}>
                        Minutes
                    </Typography>
                </Box>
                <Box borderRadius={5} sx={{ backgroundColor: 'primary.main', width: 100, height: 100, padding: 2 }}>
                    <Typography variant='h2' textAlign={'center'} color='customWhite.main' >
                        {props.seconds}
                    </Typography>
                    <Typography variant='subtitle1' textAlign={'center'} color='customWhite.main' sx={{ marginTop: 2 }}>
                        Seconds
                    </Typography>
                </Box>
            </Stack>;
        </>)
}

export default CountDownLayout