import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Footer from '../Footer';
import ContactUs from '../ContactUs';


import HomeHeader from '../HomeHeader'
import venyouMobileScreen from '../../images/venyouMobileScreen.png'
import venyouMobileVenueJoin from '../../images/venyouMobileVenueJoin.png'
import { CardMedia } from '@mui/material';

const content = {
    cards: [0, 1, 2, 3, 4, 5],
    caption: [`Exciting venues await! Tap to pick your next adventure.  Simply scroll through the list of options and click on the one you want to attend`,
        `At the venue, you'll have the option to either create your own event at the location, choose the night you want to attend, or browse the list of people attending before making a decision`,
        `To select your preferred night, simply click on the calendar icon and choose the date you wish to attend`,
        ``,
        ``],
    screens: [venyouMobileScreen, venyouMobileVenueJoin, venyouMobileVenueJoin, venyouMobileVenueJoin, venyouMobileVenueJoin, venyouMobileVenueJoin],
    heading: ['Select the Venue', 'Choose what you want to do', 'Select a date', 'Create an Event', 'Create an Event']

}

const PickAVenue = props => {
    return (
        <React.Fragment>
            <CssBaseline />
            <HomeHeader title='back' link='/support' />
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md">
                        <Typography
                            component="h1"
                            variant="h3"
                            align="center"
                            color="primary.main"
                            gutterBottom
                        >
                            Pick A Venue
                        </Typography>
                    </Container>
                </Box>
                <Container sx={{ py: 8 }} maxWidth="md">
                    {/* End hero unit */}
                    <Grid sx={{ flexGrow: 1 }} container spacing={4}>
                        {content.cards.map((card) => (
                            <Grid item key={card} xs={12} sm={6} md={4}>
                                <Card
                                    sx={{ height: '100%', display: 'flex', flexDirection: 'column-reverse' }}
                                >
                                    <CardMedia
                                        component="img"
                                        sx={{ padding: 3 }}
                                        image={content.screens[card]}
                                        alt="random"
                                    />
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant='h6' color='primary.main' textAlign='center'>
                                            {content.heading[card] + '.'}
                                        </Typography>
                                        <Typography variant='body2' color='text.secondary'>
                                            {content.caption[card] + '.'}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
            <ContactUs />
            {/* Footer */}
            <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                <Footer />
            </Box>
            {/* End footer */}
        </React.Fragment>
    )
}



export default PickAVenue