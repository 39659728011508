import { useState, useCallback, useRef, useEffect } from "react"

export const useHttpClient = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setIsError] = useState(null)

    const activeHttpRequest = useRef([])

    //useCallback is used to avoid infinate loops
    const sendRequest = useCallback(async (url, method = 'GET', body = null, headers = {}) => {
        setIsLoading(true)
        const httpAbortCtrl = new AbortController()
        activeHttpRequest.current.push(httpAbortCtrl)
        try {
            const response = await fetch(url, {
                method: method,
                body: body,
                headers: headers,
                signal: httpAbortCtrl.signal
            })

            const responseData = await response.json()

            activeHttpRequest.current = activeHttpRequest.current.filter(reqCtrl => reqCtrl !== httpAbortCtrl)

            if (!response.ok) {
                //Add this back if it's decided to handle errors the phone number size.
                //Node js is responding as an array with the errors.
                // throw responseData.message !== undefined ? responseData.message : responseData.errors[0]
                throw responseData.message
            }
            setIsLoading(false)
            return responseData

        } catch (error) {
            setIsError(error)
            setIsLoading(false)
            throw error
        }

    }, [])

    const clearError = () => {
        setIsError(null)
    }

    useEffect(() => {
        return () => {
            activeHttpRequest.current.forEach(abortCtrl => abortCtrl.abort())
        }
    }, [])


    return { isLoading, errorMessage, sendRequest, clearError }

}