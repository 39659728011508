import React from 'react'
import { Typography, Link, useMediaQuery } from '@mui/material'

const Investors = () => {

  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Typography variant={!matches ? 'caption' : 'body1'} color='text.secondary'>
      Do you want to be apart of Venyou? Become an investor?
      We are at the start up phase and would love to speak to anyone that wants to support us. Get in touch, we would love to speak to you!
      {' '}<Link href='#contact-us'>Contact Us.</Link>
    </Typography>
  )
}

export default Investors