import React from 'react'
import HomeHeader from '../HomeHeader'
import Footer from '../Footer'
import { Create } from '@mui/icons-material'

const CreateEvents = props => {
    return (
        <React.Fragment>
            <HomeHeader title='back' link='/support' />
            <div>Create Event</div>
            <Footer />
        </React.Fragment>
    )
}


export default CreateEvents