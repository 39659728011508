import * as React from 'react';
import {

    CssBaseline,

} from '@mui/material'
import Header from './Header';
import Footer from './Footer';
import About from './About';
import VenyouMovieContainer from './VenyouMovie';
import Plan from './Plan';
import Pick from './Pick';
import ContactUs from './ContactUs';
import Subscribe from './Subscribe';
import Invite from './Invite';
import Location from './Location';
import Community from './Community';



import '../App.css';
import Hero from './Hero';


const Main = () => {

    return (
        <>
            <CssBaseline />
            <Header />
            <main>
                {/* Hero unit */}
                <Hero hasLaunched={true} />
                <About />
                <VenyouMovieContainer />
                <Plan />
                <Pick />
                <Invite />
                <Location />
                <Community />
                <Subscribe />
                <ContactUs />
            </main>
            <Footer />
        </>
    );
}

export default Main