import './App.css';
import Main from './components/Main';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Pricing from './components/Pricing'
import CommunityGuideLines from './components/CommunityGuidelines';
import NotFound from './components/NotFound';
import Unsubscribed from './components/Unsubscribed';
import Support from './components/support/Support'
import PickAVenue from './components/support/PickAVenue';
import JoinEvent from './components/support/JoinEvent';
import InviteFriends from './components/support/InviteFriends';
import MeetFriends from './components/support/MeetFriends';
import SetLocation from './components/support/SetLocation';
import CreateEvents from './components/support/CreateEvents';

//This is added for the app deep linking
import InvitationHandler from './components/InvitationHandler';


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="home" element={<Main />} />
        <Route path="support" element={<Support />} />
        <Route path="pick-a-venue" element={<PickAVenue />} />
        <Route path='invitation/:id' element={<InvitationHandler />} />
        <Route path="join-event" element={<JoinEvent />} />
        <Route path="invite" element={<InviteFriends />} />
        <Route path="meet" element={<MeetFriends />} />
        <Route path="set-location" element={<SetLocation />} />
        <Route path="create" element={<CreateEvents />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="community-guidelines" element={<CommunityGuideLines />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="unsubscribed" element={<Unsubscribed />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
