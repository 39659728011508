import React from 'react'
import {
    Typography,
    Box,
    Card,
    CardMedia,
    Stack,
    Container,
    useMediaQuery
} from '@mui/material'

import venyouMobileCreateEvent from '../images/venyouMobileCreateEvent.png'
import venyouMobileInviteFriends from '../images/venyouMobileInviteFriends.png'

const Invite = () => {
    const matches = useMediaQuery('(min-width:600px)');

    let direction = 'row'
    let cardWidth = 300
    let cardMarginTop = 12.5
    if (!matches) {
        direction = 'column'
        cardWidth = 180
        cardMarginTop = 5
    }

    return (

        <Card square sx={{
            height: 900,
            paddingInline: '10%'
        }}>

            <Box alignItems='center' sx={{
                display: 'flex', flexDirection: direction, marginTop: cardMarginTop
            }}>
                <Stack direction={'row'}>
                    <CardMedia
                        component="img"
                        sx={{ width: cardWidth }}
                        image={venyouMobileCreateEvent}
                        alt='Venyou Mobile screen'
                    />
                    <CardMedia
                        component="img"
                        sx={{ width: cardWidth }}
                        image={venyouMobileInviteFriends}
                        alt='Venyou Mobile screen'
                    />

                </Stack>
                <Container maxWidth="sm" component="main" >
                    <Stack spacing={matches ? 4 : 1}>
                        <Typography variant="h5" component='h1' sx={{ fontWeight: 'bold', marginTop: 3 }} color='primary.main'>
                            Invite others
                        </Typography>
                        <Typography variant='subtitle1' color='primary.main'>
                            Create an event and invite others
                        </Typography>
                        <Typography variant='body2' align='left' color='text.secondary' >
                            Just select the venue you want to attend at a particular night.  Either search for venues near your location or select your favourite.
                        </Typography>
                        <Typography variant='subtitle1' color='primary.main'>
                            Let others invite their friends
                        </Typography>
                        <Typography variant='body2' align='left' color='text.secondary'>
                            Having an event, why not let your friends invite their friends.  If you are having a private event, you can also limit the invitation
                            to just the people you invite.
                        </Typography>
                        <Typography variant='subtitle1' color='primary.main'>
                            Promoting a nightclub or Event.
                        </Typography>
                        <Typography variant='body2' align='left' color='text.secondary'>
                            Easily promote your event and invite your contact list with minimal effort.
                        </Typography>
                    </Stack>
                </Container>
            </Box>
        </Card>
    );
}

export default Invite